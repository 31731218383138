<template>
  <v-container>
    <v-card class="text-center">
      <v-card-title>
        <v-spacer />
        <span v-text="$t('multiproject.welcome.title')" />
        <v-spacer />
      </v-card-title>

      <v-card-text class="welcome-text">
        <v-alert
          text
          prominent
          color="secondary"
          class="px-10 py-5"
        >
          <h3 v-text="$t('multiproject.waitingCriticalResource.title')" />
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'WaitingCriticalResourceContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .selected-gantt {
    border: 5px solid #506688!important;
  }
</style>
